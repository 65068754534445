import { checkBeforeEnter } from '@/auth/utils';

export default [
	{
		path: '/postores/licitaciones',
		name: 'competitions-list',
		component: () => import('@/views/modules/competitions/competitions-list/CompetitionsList.vue'),
		beforeEnter: (to, from, next) => { checkBeforeEnter(['isBidder'], to, from, next) },
		meta: {
			resource: 'ACL',
			pageTitle: 'Licitaciones',
			breadcrumb: [{
				text: 'Lista de licitaciones',
				active: true
			}],
			requiresAuth: true
		}
	},
    {
		path: '/postores/licitaciones/:id/editar',
		name: 'competitions-edit',
		component: () => import('@/views/modules/competitions/competitions-edit/CompetitionsEdit.vue'),
		beforeEnter: (to, from, next) => { checkBeforeEnter(['isBidder'], to, from, next) },
		meta: {
			resource: 'ACL',
			pageTitle: 'Licitaciones',
			breadcrumb: [
				{
					text: 'Lista de licitaciones', 
					to: {
						name: 'competitions-list',
					}
				},{
					text: 'Información de licitación',
					active: true
				}
			],
			requiresAuth: true
		}
	},
	{
		path: '/postores/licitaciones/:invitation_id/invitacion',
		name: 'competitions-invitation',
		component: () => import('@/views/modules/competitions/competitions-invitation/CompetitionsView.vue'),
		beforeEnter: (to, from, next) => { checkBeforeEnter(['isBidder'], to, from, next) },
		meta: {
			resource: 'ACL',
			pageTitle: 'Licitaciones',
			breadcrumb: [
				{
					text: 'Lista de licitaciones', 
					to: {
						name: 'competitions-list',
					}
				},{
					text: 'Información de invitación',
					active: true
				}
			],
			requiresAuth: true
		}
	}
]