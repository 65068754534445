import { checkBeforeEnter } from '@/auth/utils';
export default [
	{
		path: '/solicitudes',
		name: 'requests-list',
		component: () => import('@/views/modules/requests/requests-list/RequestsList.vue'),
		beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin', 'isManager'], to, from, next) },
		meta: {
			resource: 'requests',
			pageTitle: 'Solicitudes',
			breadcrumb: [{
				text: 'Lista de solicitudes',
				active: true
			}],
			requiresAuth: true
		}
	},
	{
		path: '/solicitudes/nuevo',
		name: 'requests-create',
		component: () => import('@/views/modules/requests/requests-store/RequestsStore.vue'),
		beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isManager'], to, from, next) },
		meta: {
			resource: 'requests',
			pageTitle: 'Solicitudes',
			breadcrumb: [
				{
					text: 'Lista de solicitudes', 
					to: {
						name: 'requests-list',
					}
				},{
					text: 'Nueva solicitud',
					active: true
				}
			],
			requiresAuth: true
		}
	},
	{
		path: '/solicitudes/:id/editar',
		name: 'requests-edit',
		component: () => import('@/views/modules/requests/requests-edit/RequestsEdit.vue'),
		beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isManager', 'isAdmin'], to, from, next) },
		meta: {
			resource: 'requests',
			pageTitle: 'Solicitudes',
			breadcrumb: [
				{
					text: 'Lista de solicitudes', 
					to: {
						name: 'requests-list',
					}
				},{
					text: 'Editar solicitud',
					active: true
				}
			],
			requiresAuth: true
		}
	},
	{
		path: '/solicitudes/:id/',
		name: 'requests-view',
		component: () => import('@/views/modules/requests/requests-view/RequestsView.vue'),
		beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin', 'isManager'], to, from, next) },
		meta: {
			resource: 'requests',
			pageTitle: 'Solicitudes',
			breadcrumb: [
				{
					text: 'Lista de solicitudes', 
					to: {
						name: 'requests-list',
					}
				},{
					text: 'Ver solicitud',
					active: true
				}
			],
			requiresAuth: true
		}
	},
]