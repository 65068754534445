import { checkBeforeEnter } from '@/auth/utils';
export default [
	{
		path: '/licitaciones',
		name: 'tenders-list',
		component: () => import('@/views/modules/tenders/tenders-list/TendersList.vue'),
		beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin', 'isBidder', 'isManager'], to, from, next) },
		meta: {
			resource: 'tenders',
			pageTitle: 'Licitaciones',
			breadcrumb: [{
				text: 'Lista de licitaciones',
				active: true
			}],
			requiresAuth: true
		}
	},
	/* {
		path: '/licitaciones/crear',
		name: 'tenders-create',
		component: () => import('@/views/modules/tenders/tenders-store/TendersStore.vue'),
		beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin', 'isManager'], to, from, next) },
		meta: {
			resource: 'tenders',
			pageTitle: 'Licitaciones',
			breadcrumb: [
				{
					text: 'Lista de licitaciones', 
					to: {
						name: 'tenders-list',
					}
				},{
					text: 'Agregar licitación',
					active: true
				}
			],
			requiresAuth: true
		}
	}, */
	{
		path: '/licitaciones/:id/editar',
		name: 'tenders-edit',
		component: () => import('@/views/modules/tenders/tenders-edit/TendersEdit.vue'),
		beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin', 'isManager'], to, from, next) },
		meta: {
			resource: 'tenders',
			pageTitle: 'Licitaciones',
			breadcrumb: [
				{
					text: 'Lista de licitaciones', 
					to: {
						name: 'tenders-list',
					}
				},{
					text: 'Gestionar licitación',
					active: true
				}
			],
			requiresAuth: true
		}
	},
	/* {
		path: '/licitaciones/:id/revisar',
		name: 'tenders-review',
		component: () => import('@/views/modules/tenders/tenders-review/TendersReview.vue'),
		beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin'], to, from, next) },
		meta: {
			resource: 'tenders',
			pageTitle: 'Licitaciones',
			breadcrumb: [{
				text: 'Lista de licitaciones', 
				to: {
					name: 'tenders-list',
				}
			},{
				text: 'Información de postores',
				active: true
			}],
			requiresAuth: true
		}
	}, */
	{
		path: '/licitaciones/:id/revisar/postores/:competition_review_id',
		name: 'tenders-bidder-review',
		component: () => import('@/views/modules/tenders/tenders-edit/evaluations/tabs-proposals/TabsProposalsEdit.vue'),
		beforeEnter: (to, from, next) => { checkBeforeEnter(['isSuperAdmin', 'isAdmin'], to, from, next) },
		meta: {
			resource: 'tenders',
			pageTitle: 'Licitaciones',
			breadcrumb: [
				{
					text: 'Lista de licitaciones', 
					to: {
						name: 'tenders-list',
					}
				}, {
					text: 'Gestionar licitación',
					to: {
						name: 'tenders-edit',
						params: (route) => ({ id: route.params.id }),
						query: { tab: '3' }
					}
				},{
					text: 'Evaluar propuestas',
					active: true
				}
			],
			requiresAuth: true
		},
	},
	{
		path: '/licitaciones/invitaciones',
		name: 'tenders-invitations',
		component: () => import('@/views/modules/invitations/invitations-list/InvitationsList.vue'),
		beforeEnter: (to, from, next) => { checkBeforeEnter(['isAdmin', 'isSuperAdmin'], to, from, next) },
		meta: {
			action: 'read',
			resource: 'invitations',
			pageTitle: 'Licitaciones',
			breadcrumb: [{
				text: 'Lista de invitaciones',
				active: true
			}],
			requiresAuth: true
		}
	},
]